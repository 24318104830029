import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactLoader from 'react-loading';

import styled from '@mui/material/styles/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import { default as MuiBackdrop } from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { setLoader } from '../../store/pages';

const LoaderComponent = styled(ReactLoader)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export default function Backdrop() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { loaderOpen } = useSelector((state) => state.pages);

  return (
    <MuiBackdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loaderOpen}
      onClick={() => dispatch(setLoader(false))}>
      <Box sx={{ width: '100%', maxWidth: 600 }}>
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(1),
            justifyContent: 'center',
            alignItems: 'center',
          })}>
          <Box
            component='img'
            sx={{ px: isMobile ? 2 : 6, py: 6, width: '100%' }}
            alt='Gridcap'
            src='/images/gridcap-logo.svg'
            title='Gridcap'
          />
          <LoaderComponent type='spin' color='grey' height='10%' width='10%' />
        </Stack>
      </Box>
    </MuiBackdrop>
  );
}
